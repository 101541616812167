<template>
  <section>
    <TabView>
      <TabPanel header="Direccionamientos">
        <programacion></programacion>
      </TabPanel>
      <TabPanel header="Importador masivo" v-if="$can('pharmasan.ventas.mipres.access')">
        <importMasivo></importMasivo>
      </TabPanel>
      <TabPanel header="Reporte de facturación" v-if="$can('pharmasan.ventas.mipres.access')">
        <reporteFact></reporteFact>
      </TabPanel>
    </TabView>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import { helper } from '../../../../utils/helper'
import programacion from './components/programacion.vue'
import importMasivo from './components/importMasivo.vue'
import reporteFact from './components/reporteFact/index.vue'

// import can from './can'
// import dayjs from 'dayjs'

export default defineComponent({
  components: {
    programacion,
    importMasivo,
    reporteFact
  },
  setup (props, { emits }) {
    // const estadoBtn = ref(2)
    return {
      helper
      // can
    }
  }
})

</script>
<style lang="scss" scoped>

.table_cards{
  text-align: start;
  font-size: 0.8em;
  margin-top: 10px;
}
.btn-atras{
  padding-top: 4px;
  padding-bottom: 4px;
}
.color-azul{
  color: #1c3faa;
}
.btn-change{
  position: absolute;
  right: 5px;
  top: 5px;
}
.img-file{
      height: 48px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}
@media(max-width: 600px){
  .card-content{
    zoom: 0.6;
  }
}
</style>
