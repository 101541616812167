<template>
    <div>
        <section class="flex my-3 justify-between">
            <div class="flex">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 my-auto">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>
                <span class="truncate my-auto">REPORTES DE FACTURACION</span>
            </div>
        </section>
        <section>
             <!-- tabla mipres -->
             <DataTable :value="storeReportesFacList.rows" class="p-datatable-sm" :rowHover="true" columnResizeMode="expand"
            :paginator="true" :rows="10" :scrollable="true" showGridlines
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll"
                currentPageReportTemplate="Registros {first} a {last} de {totalRecords}"
                    >
                <template #header>
                    <div class="flex justify-end">
                        <InputText v-model="filters.search" type="text" placeholder="Buscar" class="rounded-md" />
                    </div>
                </template>
                <Column field="ID" header="Id" :sortable="true" style="min-width:120px"></Column>
                <Column header="Estado" style="min-width:200px" alignFrozen="right" frozen>
                    <template #body="{data}">
                        <div class="relative flex items-center text-xs">
                            <div :class="`${tipoEstadoPres(data.sent, data.error)} font-semibold p-1 rounded`">
                                {{data.error ? 'ERROR' : data.sent > 0 ? 'ENVIADO Y CONTESTADO' : 'SIN ENVIAR'}}
                            </div>
                        </div>
                    </template>
                </Column>
                <Column header="Error" style="min-width:200px" :sortable="true">
                    <template #body="{data}">
                        <p style="overflow-wrap: break-word">{{data.error}}</p>
                    </template>
                </Column>
                <Column field="IdResponse" header="ID Response" style="min-width:150px" :sortable="true"></Column>
                <Column field="IdFacturacion" header="ID Facturacion" style="min-width:110px" :sortable="true"></Column>
                <Column header="N Prescripcion" style="min-width:200px" :sortable="true">
                    <template #body="{data}">
                        <p style="overflow-wrap: break-word">{{data.NoPrescripcion}}</p>
                    </template>
                </Column>
                <Column field="TipoTec" header="TipoTec" style="min-width:100px" :sortable="true"></Column>
                <Column field="ConTec" header="ConTec" style="min-width:100px"></Column>
                <Column field="NoIDPaciente" header="No Ident Pacient" style="min-width:150px" :sortable="true"></Column>
                <Column field="NoEntrega" header="N Entrega" style="min-width:100px" :sortable="true"></Column>
                <Column header="N Factura" style="min-width:300px">
                    <template #body="{data}">
                        <p style="overflow-wrap: break-word">{{data.NoFactura}}</p>
                    </template>
                </Column>
            </DataTable>
            <!-- fin tabla mipres -->
        </section>
        <Toast />
    </div>
</template>
<script>
import { computed, onMounted, ref } from 'vue'
import storeMipres from '../../stores/storeMipres'
import axios from 'axios'
// import { useRoute } from 'vue-router'
// import XLSX from 'xlsx'
// import { error } from '../../../../../../libs/mensajes'
// import Swal from 'sweetalert2'
// import dayjs from 'dayjs'
import { useToast } from 'primevue/usetoast'
export default {
    setup () {
        const storeReportesFacList = computed(() => storeMipres.getters.getReportesFacturacionList)
        const inputFile = ref()
        // const route = useRoute()
        const toast = useToast()
        const onUpload = () => {
            toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 })
        }
        const filters = ref({
            search: ''
        })

        const DownXls = (tipo) => {
            // storeMipres.dispatch('getTemplate', { tipo })
            const url = `${process.env.VUE_APP_MICROSERVICE_API_MIPRES}/export-template-xls`
            const res = axios.getUri({ url, params: { tipo } })
            // console.log('res', res)
            window.open(res, '_blank')
        }

        const cargarTemplate = () => {
            console.log('cargar template')
        }

        const getListMipres = (filters) => {
            filters.search = ''
            const params = {
                    limit: 10,
                    offset: 0,
                    search: '',
                    ...filters
                }
                // console.log('params--', params)
            storeMipres.dispatch('searchReportesFactList', { ...params })
        }

        const tipoEstadoPres = (estado, error) => {
            if (error) {
                return 'bg-red-200 text-danger'
            } else {
                switch (estado) {
                    case 0:
                        return 'bg-gray-200'
                    case 1:
                    case 2:
                        return 'bg-teal-100 text-green-700'
                    default:
                        return ''
                }
            }
        }

        const onPage = () => {
            const params = {
                    limit: 10,
                    offset: 0,
                    search: '',
                    ...filters
                }
            storeMipres.dispatch('searchReportesFactList', { ...params })
        }

        const tipoEstado = (estado) => {
            switch (estado) {
                case 'SIN PROGRAMAR':
                    return 'bg-gray-200'
                case 'PROGRAMADO':
                case 'PROGRAMACION':
                    return 'bg-purple-100 text-purple-800'
                case 'ENTREGADO':
                case 'ENTREGA':
                case 'NO ENTREGADO':
                    return 'bg-teal-100 text-green-700'
                case 'REPORTADO':
                case 'REPORTE ENTREGA':
                    return 'bg-green-light text-green-600'
                case 'ANULADO':
                    return 'bg-red-200 text-danger'
                case 'DIRECCIONAMIENTO':
                    return 'bg-blue-200 text-blue-600 text-danger'
                default:
                    return 'bg-red-200 text-danger'
            }
        }

        onMounted(() => {
            onPage()
        })

        return {
            filters,
            inputFile,
            tipoEstadoPres,
            DownXls,
            onUpload,
            getListMipres,
            cargarTemplate,
            tipoEstado,
            onPage,
            storeReportesFacList
        }
    }
}
</script>
<style lang="scss">
    .radio-import{
        height: 40px;
        width: 278px;
        background-color: #F6F6F6;
        .active{
            border: 1px solid #5887D0;
        }
    }
</style>
