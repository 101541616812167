<template>
    <div>
        <section>
            <TabView>
                <TabPanel header="Importar">
                    <importar></importar>
                </TabPanel>
                <TabPanel header="Anulación">
                    <anulacion></anulacion>
                </TabPanel>
                <TabPanel header="Consulta">
                    <consulta></consulta>
                </TabPanel>
                <TabPanel header="Listado">
                    <listado></listado>
                </TabPanel>
            </TabView>
        </section>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import importar from './importar.vue'
import anulacion from './anulacion.vue'
import consulta from './consulta.vue'
import listado from './listado.vue'
export default defineComponent({
    components: {
        importar,
        anulacion,
        consulta,
        listado
    }
    // setup () {

    //     return {
    //     }
    // }
})
</script>
<style lang="scss">

</style>
