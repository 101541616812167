<template>
    <div id="mipres-programacion">
        <div v-if="verEntregasFactura" class="flex">
            <div class="cursor-pointer" @click="(verEntregasFactura = false)">
                <i class="pi pi-arrow-circle-left"></i>
                <i class="pi pi-file ml-2"></i>
                Regresar
            </div>
        </div>
        <entregasFact v-if="verEntregasFactura"></entregasFact>
        <div v-if="!verEntregasFactura" id="faq-accordion-content-1" class="accordion-header flex justify-between">
            Filtros de busqueda
        </div>
        <div class="flex flex-wrap gap-x-6 gap-y-2 pb-4" v-if="!verEntregasFactura">
            <div class="">
                <label for="estado" class="block text-sm font-medium text-gray-700">Cliente:</label>
                <Dropdown
                    v-model="filters.cliente"
                    :options="listClientes"
                    :filter="true"
                    optionLabel="cliente_nombre"
                    optionValue="cliente_nombre"
                    placeholder="Seleccione"
                    panelClass="text-xs w-20"
                    class=" mt-1 w-full rounded-md border-gray-300"
                >
              </Dropdown>
            </div>
            <div class="">
                <label for="estado" class="block text-sm font-medium text-gray-700">Estado:</label>
                <select class="form-select mt-1" id="client" v-model="filters.estado">
                    <option :value="null" actived>-- Seleccione una opción --</option>
                    <option :value="'PROGRAMADO'">Direccionamientos Programados</option>
                    <option :value="'ANULADO'">Direccionamientos Anulados</option>
                    <option :value="'SIN PROGRAMAR'">Direccionamientos Sin Programar</option>
                    <option :value="'ENTREGADO'">Direccionamientos Entregados</option>
                    <option :value="'REPORTADO'">Direccionamientos Reportados</option>
                </select>
            </div>

            <div class="">
                <label for="start_month" class="block text-sm font-medium text-gray-700 mb-1">Fecha Desde:</label>
                <InputText
                    id="fecha_inicio"
                    type="date"
                    class="w-full p-inputtext-sm"
                    v-model="filters.date_start"
                />
            </div>
            <div class="">
                <label for="end_month" class="block text-sm font-medium text-gray-700 mb-1">Fecha Hasta:</label>
                <InputText
                id="fecha_fin"
                type="date"
                class="w-full p-inputtext-sm"
                v-model="filters.date_end"
              />
            </div>
            <button
            @click="getListMipres(filters)"
            type="button"
            class="p-button bg-primary-1 text-white py-2 px-8 mt-auto rounded-md col-auto mb-2"
            >
            Buscar
            </button>
            <button
            v-if="$can('pharmasan.ventas.mipres.access')"
            @click="programarMasivo()"
            type="button"
            class="p-button bg-success-1 text-white py-2 px-8 mt-auto rounded-md col-auto mb-2"
            >
            Programación Masiva
            </button>
            <button
            @click="limpiar()"
            type="button"
            class="p-button p-button-outlined py-2 mt-auto rounded-md col-auto mb-2"
            >
            Restablecer
            </button>
            <div class="gap-x-6 gap-y-2" >
                <label for="acciones" class="block text-sm font-medium text-gray-700">Acciones</label>
                <div class="grid grid-cols-4 pb-1">
                    <img src="/img/icon_circle_search_green.svg" class="cursor-pointer m-1" alt="" @click="modalConsultarMipres()">
                    <img src="/img/icon_circle_refresh_blue.svg" v-if="$can('pharmasan.ventas.mipres.access')" v-tooltip="'Actualizar Mipres y Programar'" class="cursor-pointer m-1" alt="" @click="modalActualizar()">
                    <img src="/img/icon_circle_sinc_blue.svg" v-if="$can('pharmasan.ventas.mipres.access')" class="cursor-pointer m-1" v-tooltip="'Actualizar Reporte de Entrega'" alt="" @click="modalEntregas()" >
                    <img src="/img/icon_circle_file_purple.svg" v-if="$can('pharmasan.ventas.mipres.access')" class="cursor-pointer m-1" v-tooltip="'Reporte de entrega N fact'" alt="" @click="verEntregasFactura = true">
                </div>
            </div>
            <div>
                <label for="acciones" class="block text-sm font-medium text-gray-700 mb-1">Exportar</label>
                <DropdownPrime v-model="selectExp" :options="tipoExp" optionLabel="name" icon="pi pi-file" placeholder="Documentos" class="rounded-md bg-gray">
                    <template #value="slotProps">
                        <i class="pi pi-file mr-1"></i>
                        <span>{{slotProps.placeholder}}</span>
                    </template>
                </DropdownPrime>
            </div>
        </div>
        <!-- tabla mipres -->
        <DataTable v-if="!verEntregasFactura" :value="storeMipresList.rows" class="p-datatable-sm" :rowHover="true" columnResizeMode="expand"
        :paginator="true" :rows="10" scrollable showGridlines
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
             responsiveLayout="scroll"
            v-model:selection="seleccionados"
                >
            <template #header>
                <div class="flex justify-end">
                    <InputText v-model="filters.search" type="text" placeholder="Buscar" class="rounded-md" />
                </div>
            </template>
            <Column headerClass="lg:text-xs" selectionMode="multiple" headerStyle="width: 3em"></Column>
            <Column header="Id" :sortable="true" style="min-width:120px">
                <template #body="{data}">
                    <p>
                        <i class="pi pi-info-circle mr-2" @click="toggle($event, data)"></i>
                        <OverlayPanel ref="InfoMipres" style="width: 70vw" :breakpoints="{'960px': '75vw'}">
                            <div class="tablaInfo">
                                <div class="w-full flex justify-around border">
                                    <div><p><b>ID</b></p><p>{{ detalleMipres.ID}}</p></div>
                                    <div><p><b>ID Direccionamiento</b></p><p>{{ detalleMipres.IDDireccionamiento}}</p></div>
                                    <div v-if="detalleMipres.DocNum"><p><b>Num Ov</b></p><p>{{ detalleMipres.DocNum}}</p></div>
                                    <div><p><b>No Prescripción</b></p><p style="overflow-wrap: break-word">{{detalleMipres.NoPrescripcion}}</p></div>
                                    <div><p><b>Fecha Dir</b></p><p>{{ detalleMipres.FechaDir}}</p></div>
                                    <div><p><b>Fecha Max Ent</b></p><p>{{ detalleMipres.FechaMaxEnt}}</p></div>
                                    <div><p><b>No Ident Pacient</b></p><p>{{ detalleMipres.NoIDPaciente}}</p></div>
                                    <div><p><b>Nombre Paciente</b></p><p>{{ detalleMipres.paciente_nombre}}</p></div>
                                    <div><p><b>Nombre EPS</b></p><p>{{ detalleMipres.cliente_nombre}}</p></div>
                                </div>
                                <div class="w-full flex justify-around border">
                                    <div><p><b>Nit EPS</b></p><p>{{ detalleMipres.NoIDEPS}}</p></div>
                                    <div><p><b>N Entrega</b></p><p>{{ detalleMipres.NoEntrega}}</p></div>
                                    <div><p><b>NomServicioTec</b></p><p style="overflow-wrap: break-word">{{detalleMipres.NomServicioTec}}</p></div>
                                    <div><p><b>Cant a Entregar</b></p><p>{{ detalleMipres.CantTotAEntregar}}</p></div>
                                    <div><p><b>Total Entregado</b></p><p>{{ detalleMipres.CantTotEntregada}}</p></div>
                                    <div><p><b>CodSerTecAEntregar</b></p><p>{{ detalleMipres.CodSerTecAEntregar}}</p></div>
                                    <div><p><b>N SubEntrega</b></p><p>{{ detalleMipres.NoSubEntrega}}</p></div>
                                    <div><p><b>CodServicioTec</b></p><p>{{ detalleMipres.CodTecEntregado}}</p></div>
                                </div>
                            </div>
                        </OverlayPanel>
                        {{data.ID}}
                    </p>
                </template>
            </Column>
            <Column field="IDDireccionamiento" header="ID Direccionamiento" style="min-width:160px" :sortable="true"></Column>
            <Column header="No Prescripción" style="min-width:200px" :sortable="true">
                <template #body="{data}">
                    <p style="overflow-wrap: break-word">{{data.NoPrescripcion}}</p>
                </template>
            </Column>
            <Column field="FechaDir" header="Fecha Dir" style="min-width:110px" :sortable="true"></Column>
            <Column field="FechaMaxEnt" header="Fecha Max Ent" style="min-width:130px" :sortable="true"></Column>
            <!-- <Column field="TipoTec" header="Tipo Servicio" :sortable="true"></Column> -->
            <!-- <Column field="TipoIDPaciente" header="Tipo Doc" style="min-width:100px" :sortable="true"></Column> -->
            <Column field="NoIDPaciente" header="No Ident Pacient" style="min-width:150px" :sortable="true"></Column>
            <Column field="paciente_nombre" header="Nombre Paciente" style="min-width:200px" :sortable="true"></Column>
            <Column field="DocNum" header="OV" style="min-width:100px" :sortable="true"></Column>
            <Column field="cliente_nombre" header="Nombre EPS" style="min-width:180px" :sortable="true"></Column>
            <Column field="NoIDEPS" header="Nit EPS" style="min-width:120px" :sortable="true"></Column>
            <Column field="NoEntrega" header="N Entrega" style="min-width:120px" :sortable="true"></Column>
            <Column header="NomServicioTec" style="min-width:250px" :sortable="true">
                <template #body="{data}">
                    <p style="overflow-wrap: break-word">{{data.NomServicioTec}}</p>
                </template>
            </Column>
            <Column field="CantTotAEntregar" header="Cant a Entregar" style="min-width:120px" :sortable="true"></Column>
            <Column field="CodSerTecAEntregar" header="CodSerTecAEntregar" style="min-width:120px" :sortable="true"></Column>
            <Column field="NoSubEntrega" header="N SubEntrega" style="min-width:140px" :sortable="true"></Column>
            <Column field="NoIdProv" header="Nit Proveedor" style="min-width:150px" :sortable="true"></Column>
            <Column field="DirPaciente" header="Dirección Paciente" style="min-width:200px" :sortable="true"></Column>
            <!-- <Column field="NoIdProv" header="Lugar Entrega Paciente" style="min-width:150px" :sortable="true"></Column> -->
            <Column field="CodTecEntregado" header="CodServicioTec" style="min-width:150px" :sortable="true"></Column>
            <Column field="CantTotEntregada" header="Total Entregado" style="min-width:150px" :sortable="true"></Column>
            <!-- <Column field="NoIdProv" header="NoIdProv" style="min-width:150px" :sortable="true"></Column> -->
            <Column header="Estado - Acciones" style="min-width:200px" alignFrozen="right" frozen>
                <template #body="{data}">
                    <div class="relative flex items-center">
                        <div :class="`${tipoEstado(data.estado)} font-semibold p-1 rounded`">
                            {{data.estado}}
                        </div>
                        <img
                        src="/img/icon_clipboard.svg"
                        @click="modalHisto(data.ID)"
                        style="width: 14px;"
                        class="mx-3 cursor-pointer">
                        <div v-show="data.estado !== 'ANULADO'" class="dropdown ml-auto sm:ml-0">
                            <button
                                class="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300"
                                aria-expanded="false"
                                v-if="$can('pharmasan.ventas.mipres.access')"
                            >
                                <span class="w-5 h-5 flex items-center justify-center">
                                    <i class="pi pi-ellipsis-h"></i>
                                </span>
                            </button>
                            <div class="dropdown-menu w-40">
                                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                <button v-show="tipoAccion(data.estado, 1)"
                                    v-if="data.estado == 'SIN PROGRAMAR'"
                                    @click="accion(data, 'Programacion')"
                                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md w-full"
                                >
                                    <i class="pi pi-arrow-up"></i>
                                    <span class="truncate">Programar</span>
                                </button>
                                <button v-show="tipoAccion(data.estado, 2)"
                                @click="accion(data.ID, 'Entregar')"
                                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md w-full"
                                >
                                    <i class="pi pi-arrow-up"></i>
                                    <span class="truncate">Entregado</span>
                                </button>
                                <button v-show="tipoAccion(data.estado, 3)"
                                @click="accion(data.ID, 'Reportar')"
                                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md w-full"
                                    >
                                    <i class="pi pi-arrow-up"></i>
                                    <span class="truncate">Rep de Entrega</span>
                                </button>
                                <button v-show="tipoAccion(data.estado, 4)"
                                @click="accion(data.ID, 'AnulaProgramacion')"
                                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md w-full"
                                >
                                    <i class="pi pi-arrow-down"></i>
                                    <span class="truncate">Eliminar Programación</span>
                                </button>
                                <button v-show="tipoAccion(data.estado, 5)"
                                @click="accion(data.ID, 'AnulaEntrega')"
                                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md w-full"
                                >
                                    <i class="pi pi-arrow-down"></i>
                                    <span class="truncate">Anular Entrega</span>
                                </button>
                                <button v-show="tipoAccion(data.estado, 6)"
                                @click="accion(data.IdReporteEntrega, 'AnulaReporte')"
                                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md w-full"
                                >
                                    <i class="pi pi-arrow-down"></i>
                                    <span class="truncate">Anular Reporte</span>
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </Column>
        </DataTable>
        <Paginator v-model:first="offset"
            :rows="limit"
            :totalRecords="parseInt(storeMipresList.count)"
            :rowsPerPageOptions="[2,10,20,30,100]"
            @page="onPage($event)"
          />
        <!-- fin tabla mipres -->
        <!-- <Paginator v-model:first="paginator.first" :rows="paginator.rows" :totalRecords="storeMipresList.count" @page="onPage($event)"></Paginator> -->
        <!-- BEGIN: Historial del registro -->
        <Dialog v-model:visible="displayHisto" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '70vw'}">
            <template v-slot:header>
                <div class="w-full">
                    <div class="flex justify-center">
                        <img src="/img/icon_clipboard.svg" style="width: 20px;" alt="">
                    </div>
                    <div class="flex">
                        <h2 class="text-center mx-auto">
                            Historial del registro
                        </h2>
                    </div>
                    <div class="flex justify-center text-xs">
                        Id {{idhisto}}
                    </div>
                </div>
            </template>
            <DataTable :value="storeMipresHist.rows" class="p-datatable-sm"
                :rowHover="true"  showGridlines
                 responsiveLayout="scroll"
                >
                <Column header="Proceso" style="min-width:170px">
                    <template #body="{data}">
                        <p :class="`font-medium py-1 text-center rounded-md ${tipoEstado(data.Proceso)}`">
                            {{data.Proceso}}
                        </p>
                    </template>
                </Column>
                <Column header="Estado" style="min-width:80px">
                    <template #body="{data}">
                        <p :class="`font-medium text-${data.Estado == 1? 'green-400': data.Estado == 0? 'red-400': 'blue-400'}`">
                            {{data.Estado == 1? 'Activado': data.Estado == 0? 'Anulado': 'Procesado'}}
                        </p>
                    </template>
                </Column>
                <Column field="fecha_pro" header="Fecha Proceso" style="min-width:130px"></Column>
                <Column field="fecha_crea" header="Fecha Registro" style="min-width:130px"></Column>
                <Column header="Empleado de Acción" style="min-width:230px">
                    <template #body="{data}">
                        <p style="overflow-wrap: break-word">{{data.employee}}</p>
                    </template>
                </Column>
                <Column field="Observacion" header="Observaciones" style="min-width:230px"></Column>
            </DataTable>
            <template #footer>
                <Button label="Cerrar" icon="pi pi-check" @click="displayHisto = false" autofocus />
            </template>
        </Dialog>
        <!-- END: Historial del registro -->

        <!-- BEGIN: Sincronizar y programar -->
        <Dialog v-model:visible="displayPrograma" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}">
            <template v-slot:header>
                <div class="w-full">
                    <div class="flex justify-center">
                        <img src="/img/icon_circle_refresh_blue.svg" class="cursor-pointer m-1" alt="">
                    </div>
                </div>
            </template>
            <div class="flex">
                <h2 class="text-center mx-auto text-2xl font-bold mb-2">
                    Sincronizar y programar
                </h2>
            </div>
            <div class="flex justify-center text-center">
                ¿Está seguro de sincronizar y programar todos los direcionamientos?
            </div>
            <div class="flex justify-center text-xs m-3">
                <div class="col-6">
                    <label for="fecha_programacion">Seleccione la una fecha</label>
                    <InputText
                        id="fecha_programacion"
                        type="date"
                        class="w-full p-inputtext-sm"
                        v-model="fechaProgramacion"
                    />
                </div>
            </div>
            <template #footer>
                <Button label="Si" icon="pi pi-check" autofocus @click="actualizar(fechaProgramacion)" />
                <Button label="No" icon="pi pi-times" class="p-button-secondary p-button-outlined" @click="displayPrograma = false" />
            </template>
        </Dialog>
        <!-- END: Sincronizar y programar -->

        <!-- BEGIN: Descargar Entregas Masivas -->
        <Dialog v-model:visible="displayEntregas" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '40vw'}">
            <template v-slot:header>
                <div class="w-full">
                    <div class="flex justify-center">
                        <img src="/img/icon_circle_refresh_blue.svg" class="cursor-pointer m-1" alt="">
                    </div>
                </div>
            </template>
            <div class="flex">
                <h2 class="text-center mx-auto text-2xl font-bold mb-2">
                    Descargar Entregas Masivas
                </h2>
            </div>
            <div class="flex justify-center text-xs m-3">
                <div class="col-6">
                    <label for="fecha_entrega_ini">Fecha Inicio</label>
                    <InputText
                        id="fecha_entrega_ini"
                        type="date"
                        class="w-full p-inputtext-sm"
                        v-model="fechaInicioEntrega"
                    />
                </div>
            </div>
            <div class="flex justify-center text-xs m-3">
                <div class="col-6">
                    <label for="fecha_entrega_fin">Fecha Fin</label>
                    <InputText
                        id="fecha_entrega_fin"
                        type="date"
                        class="w-full p-inputtext-sm"
                        v-model="fechaFinEntrega"
                    />
                </div>
            </div>
            <template #footer>
                <Button label="Aceptar" icon="pi pi-check" autofocus @click="descargarEntregas"  />
                <Button label="Cerrar" icon="pi pi-times" class="p-button-secondary p-button-outlined" @click="displayEntregas = false" />
            </template>
        </Dialog>
        <!-- END: Descargar Entregas Masivas -->

        <!-- BEGIN: Descargar Mipres Fecha -->
        <Dialog v-model:visible="displayMipresFecha" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '40vw'}">
            <template v-slot:header>
                <div class="w-full">
                    <div class="flex justify-center">
                        <!-- <img src="/img/icon_circle_refresh_blue.svg" class="cursor-pointer m-1" alt=""> -->
                        <i class="pi pi-file-excel text-4xl text-gray-500"></i>
                    </div>
                </div>
            </template>
            <div class="flex">
                <h2 class="text-center mx-auto text-2xl font-bold mb-2">
                    Descargar Mipres por fecha
                </h2>
            </div>
            <div class="flex justify-center text-xs m-3">
                <div class="col-6">
                    <label for="fecha_mipres_ini">Fecha Inicio</label>
                    <InputText
                        id="fecha_mipres_ini"
                        type="date"
                        class="w-full p-inputtext-sm"
                        v-model="fechaInicioMipres"
                    />
                </div>
            </div>
            <div class="flex justify-center text-xs m-3">
                <div class="col-6">
                    <label for="fecha_mipres_fin">Fecha Fin</label>
                    <InputText
                        id="fecha_mipres_fin"
                        type="date"
                        class="w-full p-inputtext-sm"
                        v-model="fechaFinMipres"
                    />
                </div>
            </div>
            <template #footer>
                <Button label="Aceptar" icon="pi pi-check" autofocus @click="descargarMipresFecha" />
                <Button label="Cerrar" icon="pi pi-times" class="p-button-secondary p-button-outlined" @click="modalMipresFecha" />
            </template>
        </Dialog>
        <!-- END: Descargar Mipres Fecha -->

        <!-- BEGIN: Descargar Entregas Fecha -->
        <Dialog v-model:visible="displayEntregasFecha" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '40vw'}">
            <template v-slot:header>
                <div class="w-full">
                    <div class="flex justify-center">
                        <!-- <img src="/img/icon_circle_refresh_blue.svg" class="cursor-pointer m-1" alt=""> -->
                        <i class="pi pi-file-excel text-4xl text-gray-500"></i>
                    </div>
                </div>
            </template>
            <div class="flex">
                <h2 class="text-center mx-auto text-2xl font-bold mb-2">
                    Descargar Entregas por fecha
                </h2>
            </div>
            <div class="flex justify-center text-xs m-3">
                <div class="col-6">
                    <label for="fecha_mipres_ini">Fecha Inicio</label>
                    <InputText
                        id="fecha_mipres_ini"
                        type="date"
                        class="w-full p-inputtext-sm"
                        v-model="fechaInicioRepEntrega"
                    />
                </div>
            </div>
            <div class="flex justify-center text-xs m-3">
                <div class="col-6">
                    <label for="fecha_mipres_fin">Fecha Fin</label>
                    <InputText
                        id="fecha_mipres_fin"
                        type="date"
                        class="w-full p-inputtext-sm"
                        v-model="fechaFinRepEntrega"
                    />
                </div>
            </div>
            <template #footer>
                <Button label="Aceptar" icon="pi pi-check" autofocus @click="descargarEntregasFecha" />
                <Button label="Cerrar" icon="pi pi-times" class="p-button-secondary p-button-outlined" @click="modalEntregasFecha" />
            </template>
        </Dialog>
        <!-- END: Descargar Entregas Fecha -->

        <!-- BEGIN: Consultar Mipres -->
        <Dialog v-model:visible="displayConsultarMipres" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '70vw'}">
            <template v-slot:header>
                <div class="w-full">
                    <div class="flex justify-center">
                        <img src="/img/icon_circle_search_green.svg" class="m-1" alt="">
                        <!-- <i class="pi pi-file-excel text-4xl text-gray-500"></i> -->
                    </div>
                </div>
            </template>
            <div class="flex">
                <h2 class="text-center mx-auto text-2xl font-bold mb-2">
                    Consultar Mipres
                </h2>
            </div>
            <div class="flex justify-center text-xs m-3">
                <div class="col-8">
                    <label for="fecha_mipres_ini">N. Prescripción</label>
                    <InputText
                        id="prescripcion"
                        type="text"
                        class="w-full p-inputtext"
                        v-model="NPrescripcion"
                    />
                </div>
                <Button label="Consultar" class="mt-auto ml-3" icon="pi pi-check" autofocus @click="consultarMipres(NPrescripcion)" />
            </div>
            <p></p>
            <DataTable :value="storeMipresPrescrip.rows" class="p-datatable-sm"
                :rowHover="true"  showGridlines
                 responsiveLayout="scroll"
                >
                <Column header="" style="min-width:50px" v-if="$can('pharmasan.ventas.mipres.access')">
                    <template #body="{data}">
                        <img
                        v-if="data.EstDireccionamiento == 1"
                        src="/img/icon_circle_refresh_blue.svg"
                        v-tooltip="'Programar Mipres'"
                        class="cursor-pointer m-1" alt=""
                        @click="accion(data, 'Programacion')"
                        >
                    </template>
                </Column>
                <Column field="ID" header="ID" style="min-width:130px"></Column>
                <Column field="IDDireccionamiento" header="IDDireccionamiento" style="min-width:130px"></Column>
                <Column header="NoPrescripción" style="min-width:180px">
                    <template #body="{data}">
                    <p style="overflow-wrap: break-word">{{data.NoPrescripcion}}</p>
                </template>
                </Column>
                <Column field="FecDireccionamiento" header="FecDireccionamiento" style="min-width:130px"></Column>
                <Column header="Estado" style="min-width:200px" alignFrozen="right" frozen>
                    <template #body="{data}">
                        <div class="relative flex items-center">
                            <div :class="`${tipoEstadoPres(data.EstDireccionamiento)} font-semibold p-1 rounded`">
                                {{data.EstDireccionamiento == 1 ? 'SIN PROGRAMAR': data.EstDireccionamiento == 2? 'PROGRAMADO' : 'ENTREGADO'}}
                            </div>
                        </div>
                    </template>
                </Column>
                <Column field="EstDireccionamiento" header="EstDireccionamiento" style="min-width:130px"></Column>
                <Column field="NoEntrega" header="NoEntrega" style="min-width:130px"></Column>
                <Column field="CantTotAEntregar" header="CantTotAEntregar" style="min-width:80px"></Column>
                <Column field="NoIDPaciente" header="NoIDPaciente" style="min-width:130px"></Column>
            </DataTable>
            <template #footer>
                <!-- <Button label="Aceptar" icon="pi pi-check" autofocus @click="consultarMipres(NPrescripcion)" /> -->
                <Button label="Cerrar" icon="pi pi-times" class="p-button-secondary p-button-outlined" @click="displayConsultarMipres = false" />
            </template>
        </Dialog>
        <!-- END: Consultar Mipres -->

        <!-- BEGIN: respuestas de la accion -->
        <Dialog v-model:visible="displayRespuestas" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '40vw'}">
            <template v-slot:header>
                <div class="w-full">
                    <div class="flex justify-center">
                        <!-- <img src="/img/icon_circle_refresh_blue.svg" class="cursor-pointer m-1" alt=""> -->
                        <i class="pi pi-file-excel text-4xl text-gray-500"></i>
                    </div>
                </div>
            </template>
            <div class="flex">
                <h2 class="text-center mx-auto text-2xl font-bold mb-2">
                    Respuestas de la acción
                </h2>
            </div>
            <div class="flex justify-center text-xs m-3">
                <div class="col-6">
                    <p v-for="(data, key) in storeMipresResp" :key="key" style="overflow-wrap: break-word">{{ data.Errors ? data.Errors : data }}</p>
                </div>
            </div>
            <template #footer>
                <Button label="Cerrar" icon="pi pi-times" class="p-button-secondary p-button-outlined" @click="displayRespuestas = false" />
            </template>
        </Dialog>
        <!-- END: respuestas de la accion -->
        <Toast />
    </div>
</template>
<script>
import { computed, onMounted, ref, watch } from 'vue'
import storeMipres from '../stores/storeMipres'
import ServiceMipres from '../services/listMipres'
import entregasFact from './entregasFact'
import { useRoute } from 'vue-router'
import { useStore } from '@/store'
import dayjs from 'dayjs'
import axios from 'axios'
import Swal from 'sweetalert2'
import { error } from '../../../../../libs/mensajes'
import { useToast } from 'primevue/usetoast'
import DropdownPrime from 'primevue/dropdown'
// import SideMenuTooltip from "@/components/side-menu-tooltip/Main.vue"
export default {
    components: {
        DropdownPrime,
        entregasFact
        // SideMenuTooltip
    },

    setup () {
        const store = useStore()
        const storeMipresList = computed(() => storeMipres.getters.getMipres)
        const storeEntregasList = computed(() => storeMipres.getters.getEntregasFact)
        const storeMipresHist = computed(() => storeMipres.getters.getMipresHist)
        const storeMipresPrescrip = computed(() => storeMipres.getters.getMipresPrescrip)
        const storeMipresResp = computed(() => storeMipres.getters.getRespuesta)
        const paginator = computed(() => storeMipres.getters.getPaginator)
        const today = new Date()
        const timeout = ref()
        const limit = ref(10)
        const offset = ref(0)
        const _ServiceMipres = new ServiceMipres()
        // const formatDate = dayjs(today).format('YYYY-MM-DD')
        const filters = ref({
            estado: null,
            // date_start: dayjs(today).subtract(8, 'day').format('YYYY-MM-DD'),
            date_start: '',
            date_end: '',
            search: '',
            limit: 10,
            offset: 0
        })
        const fechaProgramacion = ref(dayjs(today).format('YYYY-MM-DD'))
        const fechaInicioEntrega = ref()
        const fechaFinEntrega = ref()
        const fechaInicioRepEntrega = ref()
        const fechaFinRepEntrega = ref()
        const fechaInicioMipres = ref()
        const fechaFinMipres = ref()
        const toast = useToast()
        const idhisto = ref()
        const route = useRoute()
        const displayHisto = ref(false)
        const displayPrograma = ref(false)
        const displayEntregas = ref(false)
        const displayMipresFecha = ref(false)
        const displayEntregasFecha = ref(false)
        const displayConsultarMipres = ref(false)
        const displayRespuestas = ref(false)
        const selectExp = ref()
        const NPrescripcion = ref()
        const InfoMipres = ref(null)
        const detalleMipres = ref({})
        const listClientes = ref([])
        const seleccionados = ref([])

        const verEntregasFactura = ref(false)
        const searchEntrega = ref('')
        const facturas = ref('')
        const seleccionadasFact = ref([])
        const buscadasFact = ref([])

        const insertFact = () => {
            const a = facturas.value.split(' ')
            if (facturas.value.split(' ').length === 1) {
            if (!seleccionadasFact.value.find(a => a === facturas.value)) {
                if (facturas.value) {
                seleccionadasFact.value.push(facturas.value)
                }
            }
            } else {
                for (const i of a) {
                    if (!seleccionadasFact.value.find(a => a === i)) {
                        if (facturas.value) {
                        seleccionadasFact.value.push(i)
                        }
                    }
                }
                buscadasFact.value = seleccionadasFact.value
            }
            facturas.value = ''
        }

        const years = ref([])
        const months = [
            { id: '01', name: 'Enero' },
            { id: '02', name: 'Febrero' },
            { id: '03', name: 'Marzo' },
            { id: '04', name: 'Abril' },
            { id: '05', name: 'Mayo' },
            { id: '06', name: 'Junio' },
            { id: '07', name: 'Julio' },
            { id: '08', name: 'Agosto' },
            { id: '09', name: 'Septiembre' },
            { id: '10', name: 'Octubre' },
            { id: '11', name: 'Noviembre' },
            { id: '12', name: 'Diciembre' }
        ]

        const tipoExp = [
            { name: 'Excel Direccionamiento por fecha', action: 'MipresFecha' },
            { name: 'Excel Reporte de entrega por fecha', action: 'ReporteFecha' }
            // { name: 'PDF Reporte Entregas Print', action: 'PDFReporte' }
        ]

        const filterMonths = computed(() => {
            return months.filter((itemMonth) => {
                return parseInt(filters.value.month_start) <= parseInt(itemMonth.id)
            })
        })

        const Yearfun = () => {
            // console.log(storeMipresList.value)
            if (storeMipresList.value.rows.length) {
                var yearmin = storeMipresList.value.rows[0].yearMin
                var yearnow = new Date().getFullYear() + 1
                var range = yearnow - yearmin
                years.value = []
                for (let i = 0; i < range; i++) {
                    years.value.push(yearmin)
                    yearmin++
                }
            }
        }
        const limpiar = () => {
            filters.value = {
                estado: null,
                date_start: '',
                date_end: '',
                search: ''
            }
        }
        const getListMipres = (filters) => {
            storeMipresList.value.rows = []
            const params = {
                    limit: limit.value,
                    offset: offset.value,
                    idPettyCashIncome: route.params.id,
                    ...filters
                }
            storeMipres.dispatch('searchMipres', { ...params })
            // console.log('miprs----', storeMipresList.value.rows)
        }

        const descargarMipresFecha = () => {
            var params = { date_start: fechaInicioMipres.value, date_end: fechaFinMipres.value, search: '' }
            // console.log('params-----', params)
            const url = `${process.env.VUE_APP_MICROSERVICE_API_MIPRES}/export-mipres-fecha-xls`
            const token = store.getters['auth/getToken']
            const obj = { ...params, token }
            const res = axios.getUri({ url, params: obj })
            modalMipresFecha()
            window.open(res, '_blank')
        }

        const EntregasFactura = () => {
            const params = {
                    limit: 100,
                    offset: 0,
                    search: searchEntrega.value
                }
            storeMipres.dispatch('searchEntregaFact', { ...params })
        }

        const descargarEntregasFecha = () => {
            var params = { date_start: fechaInicioRepEntrega.value, date_end: fechaFinRepEntrega.value, search: '' }
            // console.log('params-----', params)
            const url = `${process.env.VUE_APP_MICROSERVICE_API_MIPRES}/export-entregas-fecha-xls`
            const token = store.getters['auth/getToken']
            const obj = { ...params, token }
            const res = axios.getUri({ url, params: obj })
            modalEntregasFecha()
            window.open(res, '_blank')
        }

        const descargarEntregas = () => {
            const params = {
                    fechaInicio: fechaInicioEntrega.value,
                    fechaFin: fechaFinEntrega.value
                }
            storeMipres.dispatch('descargarEntregas', { ...params }).then(() => {
                toast.add({ severity: 'success', summary: 'Exito', detail: 'Se actualizaron las entregas correctamente', life: 3000 })
                displayEntregas.value = false
                displayRespuestas.value = true
            })
        }

        const modalHisto = (id) => {
            idhisto.value = id
            storeMipres.dispatch('searchMipresHist', id)
            displayHisto.value = true
            // console.log('storeMipresHist', storeMipresHist)
        }

        const accion = (ID, tipo) => {
            var mipres = []
            if (tipo === 'AnulaReporte') {
                mipres.push({ IdReporteEntrega: ID })
            } else if (tipo === 'Programacion') {
                mipres.push(ID)
            } else {
                mipres.push({ ID })
            }
            const datos = { tipo, datos: mipres }
            // console.log('datos progama', datos)
            Swal.fire({
                title: 'Esta seguro/a?',
                text: 'de actualizar el mipres?',
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, actualizar!'
            }).then((result) => {
                if (result.isConfirmed) {
                    storeMipres.dispatch('accionMipres', { ...datos, idPettyCashIncome: route.params.id }).then(() => {
                        toast.add({ severity: 'success', summary: 'Exito', detail: 'Se actualizó el mipres correctamente', life: 3000 })
                        getListMipres(filters.value)
                        displayConsultarMipres.value = false
                        storeMipresPrescrip.value.rows = []
                    }).catch((err) => {
                        console.error(err.message)
                        error(err.response.data.message)
                    })
                } else {
                    toast.add({ severity: 'info', summary: 'Informacion', detail: 'Se cancelo correctamente', life: 3000 })
                }
                displayRespuestas.value = true
                // console.log('respuesta-----', storeMipresResp.value)
            })
        }

        const modalActualizar = () => {
            displayPrograma.value = true
        }

        const modalConsultarMipres = () => {
            displayConsultarMipres.value = true
        }

        const modalEntregas = () => {
            displayEntregas.value = true
        }

        const modalMipresFecha = () => {
            displayMipresFecha.value = !displayMipresFecha.value
            selectExp.value = ''
        }

        const modalEntregasFecha = () => {
            displayEntregasFecha.value = !displayEntregasFecha.value
            selectExp.value = ''
        }

        const consultarMipres = (nPres) => {
            storeMipres.dispatch('consultarMipres', { nPres, idPettyCashIncome: route.params.id })
        }

        const actualizar = (fecha) => {
            storeMipres.dispatch('actualizarMipres', { fecha, idPettyCashIncome: route.params.id }).then(() => {
                toast.add({ severity: 'success', summary: 'Exito', detail: 'Se actualizó el mipres correctamente', life: 3000 })
                getListMipres(filters.value)
                displayPrograma.value = false
                // displayRespuestas.value = true
            }).catch((err) => {
                console.error(err.message)
                error(err.response.data.message)
            })
        }

        const onPage = ({ first, page, pageCount, rows }) => {
            filters.value.limit = rows
            filters.value.offset = first
            // limit.value = rows
            storeMipres.dispatch('gettingPaginator', paginator.value)
            storeMipres.dispatch('searchMipres', { ...filters.value, idPettyCashIncome: route.params.id })
        }

        const tipoEstado = (estado) => {
            switch (estado) {
                case 'SIN PROGRAMAR':
                    return 'bg-gray-200'
                case 'PROGRAMADO':
                case 'PROGRAMACION':
                    return 'bg-purple-100 text-purple-800'
                case 'ENTREGADO':
                case 'ENTREGA':
                case 'NO ENTREGADO':
                    return 'bg-teal-100 text-green-700'
                case 'REPORTADO':
                case 'REPORTE ENTREGA':
                    return 'bg-green-light text-green-600'
                case 'ANULADO':
                    return 'bg-red-200 text-danger'
                case 'DIRECCIONAMIENTO':
                    return 'bg-blue-200 text-blue-600 text-danger'
                default:
                    return 'bg-red-200 text-danger'
            }
        }

        const tipoEstadoPres = (estado) => {
            switch (estado) {
                case 1:
                    return 'bg-gray-200'
                case 2:
                    return 'bg-purple-100 text-purple-800'
                default:
                    return 'bg-teal-100 text-green-700'
            }
        }

        const tipoAccion = (estado, tipo) => {
            switch (estado) {
                case 'SIN PROGRAMAR':
                    if (tipo === 1) {
                        return true
                    } else { return false }
                case 'PROGRAMADO':
                    if (tipo === 2 || tipo === 4) {
                        return true
                    } else { return false }
                case 'ENTREGADO':
                case 'NO ENTREGADO':
                    if (tipo === 3 || tipo === 5) {
                        return true
                    } else { return false }
                case 'REPORTADO':
                    if (tipo === 6) {
                        return true
                    } else { return false }
                case 'ANULADO':
                    return false
                default:
                    return false
            }
        }

        const toggle = (event, data) => {
            detalleMipres.value = data
            InfoMipres.value.toggle(event)
            // this.$refs.op.toggle(event);
        }

        const getclientes = () => {
            _ServiceMipres.getClientes().then((clientes) => {
                listClientes.value = clientes.data
            })
        }

        const programarMasivo = () => {
            console.log('programarMasivo', seleccionados.value)
            Swal.fire({
                title: 'Esta seguro/a?',
                text: 'de actualizar el mipres?',
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, actualizar!'
            }).then((result) => {
                if (result.isConfirmed) {
                    _ServiceMipres.programarMasivo(seleccionados.value).then((resp) => {
                        storeMipres.dispatch('setrespuesta', resp)
                        toast.add({ severity: 'success', summary: 'Exito', detail: 'Se programaron los mipres correctamente', life: 3000 })
                    })
                }
                displayRespuestas.value = true
                // console.log('respuesta-----', storeMipresResp.value)
            })
        }

        onMounted(() => {
            onPage(filters)
            getclientes()
        })

        watch(() => storeMipresList.value, (a) => {
            Yearfun()
        })

        watch(() => filters.value.search, (s) => {
            clearTimeout(timeout.value)
            timeout.value = setTimeout(() => {
                getListMipres(filters.value)
            }, 500) // Retrasar el llamado de la función por 500 milisegundos (0.5 segundos)
        })

        watch(() => fechaInicioMipres.value, (d) => {
            if (fechaInicioMipres.value > fechaFinMipres.value) {
                fechaFinMipres.value = ''
            }
        })
        watch(() => displayRespuestas.value, (d) => {
            if (displayRespuestas.value === false) {
                storeMipresResp.value = []
                getListMipres(filters.value)
            }
        })
        watch(() => selectExp.value, (a) => {
            if (selectExp.value.action === 'MipresFecha') {
                modalMipresFecha()
            }
            if (selectExp.value.action === 'ReporteFecha') {
                modalEntregasFecha()
            } else {}
        })

        return {
            limit,
            offset,
            getListMipres,
            limpiar,
            onPage,
            toggle,
            InfoMipres,
            detalleMipres,
            tipoEstado,
            tipoEstadoPres,
            tipoAccion,
            modalHisto,
            modalMipresFecha,
            modalEntregasFecha,
            accion,
            modalActualizar,
            modalEntregas,
            actualizar,
            descargarMipresFecha,
            descargarEntregasFecha,
            descargarEntregas,
            EntregasFactura,
            insertFact,
            consultarMipres,
            modalConsultarMipres,
            verEntregasFactura,
            facturas,
            seleccionadasFact,
            buscadasFact,
            idhisto,
            displayHisto,
            displayPrograma,
            displayEntregas,
            displayMipresFecha,
            displayEntregasFecha,
            displayConsultarMipres,
            displayRespuestas,
            NPrescripcion,
            tipoExp,
            selectExp,
            filters,
            months,
            years,
            filterMonths,
            paginator,
            storeMipresList,
            storeEntregasList,
            storeMipresPrescrip,
            storeMipresResp,
            fechaProgramacion,
            fechaInicioEntrega,
            fechaFinEntrega,
            fechaInicioRepEntrega,
            fechaFinRepEntrega,
            fechaInicioMipres,
            fechaFinMipres,
            storeMipresHist,
            listClientes,
            getclientes,
            seleccionados,
            programarMasivo
            // can
        }
    }
}
</script>
<style lang="scss">
    #mipres-programacion .p-dialog-header{
        width: 100%;
    }
    .bg-gray{
        background-color: lightgray;
    }
    .p-datatable .p-datatable-thead > tr > th {
        opacity: 1!important;
    }
    .bg-green-light {
       background-color: #58AB3045;
    }
    .tablaInfo div{
        text-align: center;
        padding: 2px;
    }
</style>
