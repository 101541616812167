<template>
    <div>
        <section class="grid grid-cols-1">
            <div class="flex my-3">
                <img class="mr-2" style="width: 22px; height: 20px" src="/img/icon_file_slash.svg"
                  alt="Icono historial" />
                <span class="truncate my-auto">ANULACIÓN DE REPORTES</span>
            </div>
            <div class="flex w-full">
                <div class="w-1/2">
                    <div>
                        <h5>ID Facturación:</h5>
                        <InputText type="text" v-model="idSelected" @keyup.enter="idInsert" class="w-full" />
                    </div>
                    <!-- <span v-for="(dato, key) in idSeleccionadas" :key="key">{{dato}}</span> -->
                </div>
                <button
                    class="btn px-2 bg-primary-1 text-white mx-2 mt-auto"
                    @click="accion('anularReporteFact')"
                >
                    <span class="flex items-center justify-center">
                        <i class="pi pi-send mr-2"></i>
                        Enviar
                    </span>
                </button>
                <Button label="Cerrar" icon="pi pi-trash" class="p-button-secondary p-button-outlined mt-auto" @click="idSelected = ''" />
            </div>
            <div class="cajaIngresados bg-white rounded-md flex flex-wrap">
                <div class="mt-2" v-for="(seleccionada, o) in idSeleccionadas" :key="o">
                    <div
                    class="p-1 mx-1 text-xs flex items-center w-fit inline text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800"
                    role="alert">
                    <button @click="borrarOrden(o)">
                        <svg class="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                        </path>
                        </svg>
                    </button>
                    {{ seleccionada }}
                    </div>
                </div>
            </div>
        </section>
        <!-- BEGIN: Respuestas de la acción -->
        <Dialog v-model:visible="displayRespuestas" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '40vw'}">
            <template v-slot:header>
                <div class="w-full">
                    <div class="flex justify-center">
                        <!-- <img src="/img/icon_circle_refresh_blue.svg" class="cursor-pointer m-1" alt=""> -->
                        <i class="pi pi-file-excel text-4xl text-gray-500"></i>
                    </div>
                </div>
            </template>
            <div class="flex">
                <h2 class="text-center mx-auto text-2xl font-bold mb-2">
                    Respuestas de la acción
                </h2>
            </div>
            <div class="flex justify-center text-xs m-3">
                <div class="col-6" style="max-width: 100%">
                    <p v-for="(data, key) in storeMipresResp" :key="key" style="overflow-wrap: break-word">{{ data.Errors ? data.Errors : data }}</p>
                </div>
            </div>
            <template #footer>
                <Button label="Cerrar" icon="pi pi-times" class="p-button-secondary p-button-outlined" @click="displayRespuestas = false" />
            </template>
        </Dialog>
        <!-- END: Respuestas de la acción -->
        <Toast />
    </div>
</template>
<script>
import { computed, onMounted, ref } from 'vue'
import storeMipres from '../../stores/storeMipres'
import { useRoute } from 'vue-router'
import { error } from '../../../../../../libs/mensajes'
import Swal from 'sweetalert2'
import { useToast } from 'primevue/usetoast'
export default {
    setup () {
        const storeMipresList = computed(() => storeMipres.getters.getMipresMasivo)
        const storeMipresResp = computed(() => storeMipres.getters.getRespuesta)
        const inputFile = ref()
        const datosExcel = ref()
        const route = useRoute()
        const toast = useToast()
        const idSelected = ref('')
        const idsBuscadas = ref([])
        const idSeleccionadas = ref([])
        const displayRespuestas = ref(false)
        const idInsert = () => {
            const a = idSelected.value.split(' ')
            if (idSelected.value.split(' ').length === 1) {
            if (!idSeleccionadas.value.find(a => a === idSelected.value)) {
                if (idSelected.value) {
                idSeleccionadas.value.push(idSelected.value)
                }
            }
            } else {
                for (const i of a) {
                    if (!idSeleccionadas.value.find(a => a === i)) {
                        if (idSelected.value) {
                        idSeleccionadas.value.push(i)
                        }
                    }
                }
                idsBuscadas.value = idSeleccionadas.value
            }
            idSelected.value = ''
        }
        const borrarOrden = (indice) => {
            idSeleccionadas.value.splice(indice, 1)
            idsBuscadas.value = idSeleccionadas.value
        }

        const filters = ref({
            search: ''
        })

        const getListMipres = (filters) => {
            filters.search = ''
            const params = {
                    limit: 10,
                    offset: 0,
                    // idPettyCashIncome: route.params.id,
                    ...filters
                }
                // console.log('params--', params)
            storeMipres.dispatch('searchMipresMasivo', { ...params })
        }

        const accion = (tipo) => {
            idInsert()
            var ids = idSeleccionadas.value
            const dataEnvie = { tipo, datos: ids }
            Swal.fire({
                title: 'Esta seguro/a?',
                text: 'de anular los los reportes de facturación?',
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, anular!'
            }).then((result) => {
                if (result.isConfirmed) {
                    storeMipres.dispatch('accionMipres', { ...dataEnvie, idPettyCashIncome: route.params.id }).then(() => {
                        toast.add({ severity: 'success', summary: 'Exito', detail: 'Se actualizaron los mipres correctamente', life: 3000 })
                        idSeleccionadas.value = []
                        idSelected.value = ''
                    }).catch((err) => {
                        toast.add({ severity: 'error', summary: 'Informacion', detail: 'Error al anular los reportes', life: 3000 })
                        console.error(err.message)
                        error(err.response.data.message)
                    })
                } else {
                    toast.add({ severity: 'info', summary: 'Informacion', detail: 'Se cancelo correctamente', life: 3000 })
                }
                displayRespuestas.value = true
            })
        }

        onMounted(() => {
        })
        const TipoImport = ref('')

        return {
            idInsert,
            borrarOrden,
            idsBuscadas,
            idSelected,
            idSeleccionadas,
            filters,
            datosExcel,
            inputFile,
            TipoImport,
            storeMipresList,
            storeMipresResp,
            displayRespuestas,
            accion,
            getListMipres
        }
    }
}
</script>
<style lang="scss">
    .radio-import{
        height: 40px;
        width: 278px;
        background-color: #F6F6F6;
        .active{
            border: 1px solid #5887D0;
        }
    }
</style>
