<template>
    <div>
        <section class="flex my-3 justify-between">
            <div class="flex">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 my-auto">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>
                <span class="truncate my-auto">IMPORTADOR REPORTE DE FACTURACIÓN</span>
            </div>
            <div class="dropdown ml-auto sm:ml-0">
                <button
                    class="dropdown-toggle btn px-2 bg-gray-200"
                    aria-expanded="false"
                >
                    <span class="flex items-center justify-center">
                        <i class="pi pi-download mr-2"></i>
                        Descargar plantillas
                    </span>
                </button>
                <div class="dropdown-menu" style="width: 260px;">
                    <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                        <button @click="DownXls('ReportFact')"
                            class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md w-full">
                            <span class="truncate">Excel Reportar Facturas</span>
                        </button>
                    </div>
                </div>
            </div>
            <button
                class="btn px-2 bg-primary-1 text-white mx-2"
                @click="accion(TipoImport)"
            >
                <span class="flex items-center justify-center">
                    <i class="pi pi-send mr-2"></i>
                    Enviar
                </span>
            </button>
        </section>
        <section class="grid grid-cols-2">
            <div class="flex flex-wrap">
                <p class="w-full">Seleccionar tipo de importación:</p>
                <div class="field-radiobutton radio-import p-2 m-2">
                    <RadioButton inputId="entregaMasiva" name="entregaMasiva" value="ReportFact" v-model="TipoImport" />
                    <label for="entregaMasiva" class="ml-2">Reportar Facturación</label>
                </div>
            </div>
            <div class="">
                <FileUpload name="demo[]" url="./upload.php" :showUploadButton="false" accept=".xls, .xlsx" :maxFileSize="1000000" @upload="onUpload" @change="handleFilesUpload()" ref="inputFile">
                <template #empty>
                    <p>Arrastra el documento aquí</p>
                </template>
            </FileUpload>
            </div>
        </section>
        <section>
            <DataTable v-if="datosTabla.length" :value="datosTabla" class="p-datatable-sm" :rowHover="true" columnResizeMode="expand"
                :paginator="true" :rows="10" :scrollable="true" showGridlines
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll"
                currentPageReportTemplate="Registros {first} a {last} de {totalRecords}"
                    >
                <template #header>
                    <div class="flex justify-end">
                        <InputText v-model="filters.search" type="text" placeholder="Buscar" class="rounded-md" />
                    </div>
                </template>
                <!-- <Column field="ID" header="Id" :sortable="true" style="min-width:120px"></Column> -->
                <Column field="IDFacturacion" header="ID Direccionamiento" style="min-width:150px" :sortable="true"></Column>
                <Column header="No Prescripción" style="min-width:200px" :sortable="true">
                    <template #body="{data}">
                        <p style="overflow-wrap: break-word">{{data.NoPrescripcion}}</p>
                    </template>
                </Column>
                <Column field="TipoTec" header="Fecha Dir" style="min-width:110px" :sortable="true"></Column>
                <Column field="ConTec" header="Fecha Max Ent" style="min-width:130px" :sortable="true"></Column>
                <Column field="TipoIDPaciente" header="Tipo Doc" style="min-width:110px" :sortable="true"></Column>
                <Column field="NoIDPaciente" header="No Ident Pacient" style="min-width:150px" :sortable="true"></Column>
                <Column field="NoEntrega" header="N Entrega" style="min-width:120px" :sortable="true"></Column>
                <!-- <Column field="NoFactura" header="NoFactura" style="min-width:140px" :sortable="true"></Column> -->
                <Column header="NoFactura" style="min-width:200px" :sortable="true">
                    <template #body="{data}">
                        <p class="w-full" style="overflow-wrap: break-word">{{data.NoFactura}}</p>
                    </template>
                </Column>
                <Column field="NoIDEPS" header="Nit Proveedor" style="min-width:150px" :sortable="true"></Column>
                <Column field="CodEPS" header="Dirección Paciente" style="min-width:150px" :sortable="true"></Column>
                <Column field="CausaNoEntrega" header="Lugar Entrega Paciente" style="min-width:150px" :sortable="true"></Column>
                <Column field="CodSerTecAEntregado" header="CodServicioTec" style="min-width:150px" :sortable="true"></Column>
                <Column field="CantUnMinDis" header="Total Entrega" style="min-width:150px" :sortable="true"></Column>
                <Column field="ValorUnitFacturado" header="NomServicioTec" style="min-width:150px" :sortable="true"></Column>
                <Column field="ValorTotFacturado" header="NomServicioTec" style="min-width:150px" :sortable="true"></Column>
                <Column field="CuotaModer" header="NomServicioTec" style="min-width:150px" :sortable="true"></Column>
                <Column field="Copago" header="NomServicioTec" style="min-width:150px" :sortable="true"></Column>
                <Column field="NoSubEntrega" header="NomServicioTec" style="min-width:150px" :sortable="true"></Column>
                <!-- <Column header="Estado Actual" style="min-width:150px" alignFrozen="right" frozen>
                    <template #body="{data}">
                        <div class="relative flex items-center">
                            <div :class="`${tipoEstado(data.estado)} font-semibold p-1 rounded`">
                                {{data.estado}}
                            </div>
                        </div>
                    </template>
                </Column> -->
            </DataTable>
        </section>
        <!-- BEGIN: respuestas de la accion -->
        <Dialog v-model:visible="displayRespuestas" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '40vw'}">
            <template v-slot:header>
                <div class="w-full">
                    <div class="flex justify-center">
                        <!-- <img src="/img/icon_circle_refresh_blue.svg" class="cursor-pointer m-1" alt=""> -->
                        <i class="pi pi-file-excel text-4xl text-gray-500"></i>
                    </div>
                </div>
            </template>
            <div class="flex">
                <h2 class="text-center mx-auto text-2xl font-bold mb-2">
                    Respuestas de la acción
                </h2>
            </div>
            <div class="flex justify-center text-xs m-3">
                <div class="col-6">
                    <p v-for="(data, key) in storeMipresResp" :key="key" style="overflow-wrap: break-word">{{ data.Errors ? data.Errors : data }}</p>
                </div>
            </div>
            <template #footer>
                <Button label="Cerrar" icon="pi pi-times" class="p-button-secondary p-button-outlined" @click="displayRespuestas = false" />
            </template>
        </Dialog>
        <!-- END: respuestas de la accion -->
        <Toast />
    </div>
</template>
<script>
import { computed, onMounted, ref } from 'vue'
import storeMipres from '../../stores/storeMipres'
import axios from 'axios'
import { useRoute } from 'vue-router'
import { useStore } from '@/store'
import XLSX from 'xlsx'
import { error } from '../../../../../../libs/mensajes'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
import { useToast } from 'primevue/usetoast'
export default {
    setup () {
        const store = useStore()
        const TipoImport = ref('ReportFact')
        const datosTabla = ref([])
        const storeMipresResp = computed(() => storeMipres.getters.getRespuesta)
        const displayRespuestas = ref(false)

        const storeMipresList = computed(() => storeMipres.getters.getMipresMasivo)
        const inputFile = ref()
        const datosExcel = ref()
        const route = useRoute()
        const toast = useToast()
        const onUpload = () => {
            toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 })
        }
        const filters = ref({
            search: ''
        })

        const DownXls = (tipo) => {
            // storeMipres.dispatch('getTemplate', { tipo })
            const url = `${process.env.VUE_APP_MICROSERVICE_API_MIPRES}/export-template-xls`
            const token = store.getters['auth/getToken']
            const obj = { tipo: tipo, token }
            const res = axios.getUri({ url, params: obj })
            // console.log('res', res)
            window.open(res, '_blank')
        }

        const cargarTemplate = () => {
            console.log('cargar template')
        }

        const getListMipres = (filters) => {
            filters.search = ''
            const params = {
                    limit: 10,
                    offset: 0,
                    // idPettyCashIncome: route.params.id,
                    ...filters
                }
                // console.log('params--', params)
            storeMipres.dispatch('searchMipresMasivo', { ...params })
        }

        const accion = (tipo) => {
            var datosExc = datosExcel.value
            // for (const item of datosExcel.value) {
            //     for (const f of storeMipresList.value.rows) {
            //         switch (TipoImport.value) {
            //             case 'ReportFact':
            //                 if (f.NoPrescripcion === (+item.NoPrescripcion)) {
            //                     item.id_mipres = f.ID
            //                 }
            //                 break
            //             default:
            //                 break
            //         }
            //     }
            // }
            const datos = { tipo, datos: datosExc }
            Swal.fire({
                title: 'Esta seguro/a?',
                text: 'de realizar el reporte de facturación?',
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, Reportar'
            }).then((result) => {
                if (result.isConfirmed) {
                    storeMipres.dispatch('accionMipres', { ...datos, idPettyCashIncome: route.params.id }).then(() => {
                        toast.add({ severity: 'success', summary: 'Exito', detail: 'Se actualizaron los mipres correctamente', life: 3000 })
                        datosTabla.value = []
                        inputFile.value.files = []
                    }).catch((err) => {
                        console.error(err.message)
                        error(err.response.data.message)
                    })
                } else {
                    toast.add({ severity: 'info', summary: 'Informacion', detail: 'Se cancelo correctamente', life: 3000 })
                }
                displayRespuestas.value = true
            })
        }

        const handleFilesUpload = () => {
            // loader.show()
            const file = inputFile.value.files[0]
            if (file) {
                const reader = new FileReader()
                reader.onload = (event) => {
                    const result = event.target.result
                    const dataOne = new Uint8Array(result)
                    const workbook = XLSX.read(dataOne, { type: 'array', cellDates: true })
                    const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]]
                    const data = XLSX.utils.sheet_to_json(firstWorksheet, { header: 2, raw: false, dateNf: dayjs('DD/MM/YYYY').format('YYYY-MM-DD') })
                    // console.log('dataaa-----', inputFile.value.files)
                    datosExcel.value = data
                    // console.log('data---', data)
                    if (data.length === 0) {
                        toast.add({ severity: 'info', summary: 'Informacion', detail: 'No se encontraron mipres', life: 3000 })
                    } else {
                        datosTabla.value = data
                        // getListMipres({ NoPrescripcion: data.map(f => f.NoPrescripcion) })
                    }
                }
                reader.readAsArrayBuffer(file)
            }
        }

        const tipoEstado = (estado) => {
            switch (estado) {
                case 'SIN PROGRAMAR':
                    return 'bg-gray-200'
                case 'PROGRAMADO':
                case 'PROGRAMACION':
                    return 'bg-purple-100 text-purple-800'
                case 'ENTREGADO':
                case 'ENTREGA':
                case 'NO ENTREGADO':
                    return 'bg-teal-100 text-green-700'
                case 'REPORTADO':
                case 'REPORTE ENTREGA':
                    return 'bg-green-light text-green-600'
                case 'ANULADO':
                    return 'bg-red-200 text-danger'
                case 'DIRECCIONAMIENTO':
                    return 'bg-blue-200 text-blue-600 text-danger'
                default:
                    return 'bg-red-200 text-danger'
            }
        }

        onMounted(() => {
        })

        return {
            filters,
            datosExcel,
            datosTabla,
            inputFile,
            TipoImport,
            storeMipresList,
            storeMipresResp,
            displayRespuestas,
            accion,
            DownXls,
            onUpload,
            getListMipres,
            cargarTemplate,
            handleFilesUpload,
            tipoEstado
        }
    }
}
</script>
<style lang="scss">
    .radio-import{
        height: 40px;
        width: 278px;
        background-color: #F6F6F6;
        .active{
            border: 1px solid #5887D0;
        }
    }
</style>
