<template>
    <div>
        <section class="grid grid-cols-1">
            <div class="flex my-3">
                <i class="pi pi-file text-2xl"></i>
                <span class="truncate my-auto">CONSULTA DE REPORTES DE FACTURACIÓN</span>
            </div>
            <div class="flex w-full my-3">
                <div class="m-2">
                    <h5>Tipo de consulta:</h5>
                    <Dropdown v-model="selectedTipo" :options="tipo" class=""/>
                </div>
                <div v-if="selectedTipo == 'Fecha'" class="m-2">
                    <h5>Fecha:</h5>
                    <InputText
                    id="fecha_inicio"
                    type="date"
                    class="p-inputtext-sm"
                    v-model="fecha"
                />
                </div>
                <div v-if="selectedTipo == 'Prescripcion'" class="m-2">
                    <h5>NoPrescripción:</h5>
                    <InputText type="text" v-model="prescripcion" class="" />
                </div>
                <button
                    class="btn px-2 bg-primary-1 text-white mx-2 mt-auto m-2 ml-3"
                    @click="getListReportesFact()"
                >
                    <span class="flex items-center justify-center">
                        <i class="pi pi-search mr-2"></i>
                        Consultar
                    </span>
                </button>
            </div>
        </section>
        <DataTable :value="storeReportesFactList.rows" class="p-datatable-sm" :rowHover="true" columnResizeMode="expand"
            :paginator="true" :rows="10" :scrollable="true" showGridlines
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll"
            currentPageReportTemplate="Registros {first} a {last} de {totalRecords}"
                >
            <template #header>
                <div class="flex justify-end">
                    <InputText v-model="filters.search" type="text" placeholder="Buscar" class="rounded-md" />
                </div>
            </template>
            <Column field="ID" header="Id" :sortable="true" style="min-width:120px"></Column>
            <Column field="IDFacturacion" header="ID Facturacion" style="min-width:150px" :sortable="true"></Column>
            <Column header="No Prescripción" style="min-width:200px" :sortable="true">
                <template #body="{data}">
                    <p style="overflow-wrap: break-word">{{data.NoPrescripcion}}</p>
                </template>
            </Column>
            <Column field="TipoTec" header="Tipo tec" style="min-width:110px" :sortable="true"></Column>
            <Column field="ConTec" header="ConTec" style="min-width:130px" :sortable="true"></Column>
            <Column field="TipoIDPaciente" header="TipoIDPaciente" style="min-width:110px" :sortable="true"></Column>
            <Column field="NoIDPaciente" header="NoIDPaciente" style="min-width:150px" :sortable="true"></Column>
            <Column field="NoEntrega" header="NoEntrega" style="min-width:200px" :sortable="true"></Column>
            <!-- <Column field="NoFactura" header="NoFactura" style="min-width:180px" :sortable="true"></Column> -->
            <Column header="NoFactura" style="min-width:200px" :sortable="true">
                <template #body="{data}">
                    <p style="overflow-wrap: break-word">{{data.NoFactura}}</p>
                </template>
            </Column>
            <Column field="NoIDEPS" header="NoIDEPS" style="min-width:120px" :sortable="true"></Column>
            <Column field="CodEPS" header="CodEPS" style="min-width:120px" :sortable="true"></Column>
            <Column field="CodSerTecAEntregado" header="CodSerTecAEntregado" style="min-width:140px" :sortable="true"></Column>
            <Column field="CantUnMinDis" header="CantUnMinDis" style="min-width:150px" :sortable="true"></Column>
            <Column field="ValorUnitFacturado" header="ValorUnitFacturado" style="min-width:150px" :sortable="true"></Column>
            <Column field="ValorTotFacturado" header="ValorTotFacturado" style="min-width:150px" :sortable="true"></Column>
            <Column field="CuotaModer" header="CuotaModer" style="min-width:150px" :sortable="true"></Column>
            <Column field="Copago" header="Copago" style="min-width:150px" :sortable="true"></Column>
            <Column field="NoIdProv" header="NoIdProv" style="min-width:150px" :sortable="true"></Column>
            <Column field="FecFacturacion" header="FecFacturacion" style="min-width:150px" :sortable="true"></Column>
            <Column field="EstFacturacion" header="EstFacturacion" style="min-width:150px" :sortable="true"></Column>
            <Column field="FecAnulacion" header="FecAnulacion" style="min-width:150px" :sortable="true"></Column>
        </DataTable>
        <Toast />
    </div>
</template>
<script>
import { computed, onMounted, ref } from 'vue'
import storeMipres from '../../stores/storeMipres'
// import { useRoute } from 'vue-router'
import dayjs from 'dayjs'
import { useToast } from 'primevue/usetoast'
export default {
    setup () {
        const storeReportesFactList = computed(() => storeMipres.getters.getReportesFacturacion)
        const inputFile = ref()
        const datosExcel = ref()
        const estaCargando = ref(false)
        // const route = useRoute()
        const toast = useToast()
        // const today = new Date()
        // const fecha = dayjs(today).format('YYYY-MM-DD')
        const fecha = ref('')
        const prescripcion = ref('')

        const onUpload = () => {
            toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 })
        }

        const selectedTipo = ref('Fecha')
        const tipo = ref(['Fecha', 'Prescripcion'])

        const filters = ref({
            search: ''
        })

        const getListReportesFact = () => {
            var filters = {}
            filters.tipo = selectedTipo.value
            filters.fecha = dayjs(fecha.value).format('YYYY-MM-DD')
            filters.prescripcion = prescripcion.value
            const params = {
                    limit: 10,
                    offset: 0,
                    ...filters
                }
                console.log('params--', filters)
                estaCargando.value = true
            storeMipres.dispatch('searchReportesFact', { ...params }).then((data) => {
                estaCargando.value = false
            }).catch(err => {
                estaCargando.value = false
                console.error(err.message)
            })
        }

        const accion = () => {
            getListReportesFact()
        }

        const tipoEstado = (estado) => {
            switch (estado) {
                case 'SIN PROGRAMAR':
                    return 'bg-gray-200'
                case 'PROGRAMADO':
                case 'PROGRAMACION':
                    return 'bg-purple-100 text-purple-800'
                case 'ENTREGADO':
                case 'ENTREGA':
                case 'NO ENTREGADO':
                    return 'bg-teal-100 text-green-700'
                case 'REPORTADO':
                case 'REPORTE ENTREGA':
                    return 'bg-green-light text-green-600'
                case 'ANULADO':
                    return 'bg-red-200 text-danger'
                case 'DIRECCIONAMIENTO':
                    return 'bg-blue-200 text-blue-600 text-danger'
                default:
                    return 'bg-red-200 text-danger'
            }
        }

        onMounted(() => {
        })
        const TipoImport = ref('')

        // watch(() => storeReportesFactList.search, (s) => {
        //     estaCargando = false
        // })

        return {
            estaCargando,
            selectedTipo,
            tipo,
            filters,
            datosExcel,
            inputFile,
            TipoImport,
            storeReportesFactList,
            prescripcion,
            fecha,
            accion,
            onUpload,
            getListReportesFact,
            tipoEstado
        }
    }
}
</script>
<style lang="scss">
    .radio-import{
        height: 40px;
        width: 278px;
        background-color: #F6F6F6;
        .active{
            border: 1px solid #5887D0;
        }
    }
</style>
