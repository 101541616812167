<template>
    <div >
        <div >
            <div class="flex text-2xl text-blue-700 font-medium mb-4">
                Entregas por N. factura
            </div>
            <div class="flex">
                <div class="w-1/2">
                    <h5>No. Factura:</h5>
                    <InputText type="text" v-model="facturas" @keyup.enter="insertFact" class="w-full" />
                </div>
            </div>
            <div class="cajaIngresados bg-white rounded-md flex flex-wrap">
                <div class="mt-2" v-for="(seleccionada, o) in seleccionadasFact" :key="o">
                    <div
                    class="p-1 mx-1 text-xs flex items-center w-fit inline text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800"
                    role="alert">
                    <button @click="borrarOrden(o)">
                        <svg class="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                        </path>
                        </svg>
                    </button>
                    {{ seleccionada }}
                    </div>
                </div>
            </div>
        </div>
        <div id="faq-accordion-content-1" class="accordion-header flex justify-between">
            Filtros de busqueda
        </div>
        <div class="flex flex-wrap gap-x-6 gap-y-2 pb-4">
            <button
            @click="EntregasFactura()"
            type="button"
            class="p-button bg-primary-1 text-white py-2 px-8 mt-auto rounded-md col-auto mb-2"
            >
            Buscar
            </button>
            <button
            @click="limpiar()"
            type="button"
            class="p-button p-button-outlined py-2 mt-auto rounded-md col-auto mb-2"
            >
            Restablecer
            </button>
            <div class="gap-x-6 gap-y-2" v-if="$can('pharmasan.ventas.mipres.access')">
                <label for="acciones" class="block text-sm font-medium text-gray-700">Acciones</label>
                <div class="grid grid-cols-4 pb-1">
                    <img src="/img/icon_circle_sinc_blue.svg" class="cursor-pointer m-1" v-tooltip="'Actualizar Reporte de Entrega'" alt="" @click="modalEntregas()" >
                </div>
            </div>
            <button class="btn btn-dark-soft mt-auto" @click="modalDescargapdf">Exportar PDF</button>
        </div>
        <!-- tabla entregas facturas -->
        <DataTable :value="storeEntregasList.rows" class="p-datatable-sm" :rowHover="true" columnResizeMode="expand"
            :paginator="true" :rows="10" :scrollable="true" showGridlines
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll"
            currentPageReportTemplate="Registros {first} a {last} de {totalRecords}"
                >
            <template #header>
                <div class="flex justify-end">
                    <InputText v-model="searchEntrega" type="text" placeholder="Buscar" class="rounded-md" />
                </div>
            </template>
            <Column header="Estado - Acciones" style="min-width:200px" alignFrozen="right" frozen>
                <template #body="{data}">
                    <div class="relative flex items-center justify-center w-full">
                        <div :class="`${tipoEstado(data.estado)} font-semibold p-1 rounded`">
                            {{data.estado ==1 ? 'LISTO':'PENDIENTE'}}
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="IdFact" header="IdFact" :sortable="true" style="min-width:120px"></Column>
            <Column field="IdRepEntrega" header="IdRepEntrega" style="min-width:150px" :sortable="true"></Column>
            <Column header="No Prescripción" style="min-width:200px" :sortable="true">
                <template #body="{data}">
                    <p style="overflow-wrap: break-word">{{data.NoPrescr}}</p>
                </template>
            </Column>
            <Column field="NoFacturacion" header="NoFacturacion" style="min-width:110px" :sortable="true"></Column>
        </DataTable>
        <!-- fin tabla entregas facturas -->
        <!-- <Paginator v-model:first="paginator.first" :rows="paginator.rows" :totalRecords="storeMipresList.count" @page="onPage($event)"></Paginator> -->
        <!-- BEGIN: Descargar Entregas Masivas -->
        <Dialog v-model:visible="displayEntregas" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '40vw'}">
            <template v-slot:header>
                <div class="w-full">
                    <div class="flex justify-center">
                        <img src="/img/icon_circle_refresh_blue.svg" class="cursor-pointer m-1" alt="">
                    </div>
                </div>
            </template>
            <div class="flex">
                <h2 class="text-center mx-auto text-2xl font-bold mb-2">
                    Descargar Entregas Masivas
                </h2>
            </div>
            <div class="flex justify-center text-xs m-3">
                <div class="col-6">
                    <label for="fecha_entrega_ini">Fecha Inicio</label>
                    <InputText
                        id="fecha_entrega_ini"
                        type="date"
                        class="w-full p-inputtext-sm"
                        v-model="fechaInicioEntrega"
                    />
                </div>
            </div>
            <div class="flex justify-center text-xs m-3">
                <div class="col-6">
                    <label for="fecha_entrega_fin">Fecha Fin</label>
                    <InputText
                        id="fecha_entrega_fin"
                        type="date"
                        class="w-full p-inputtext-sm"
                        v-model="fechaFinEntrega"
                    />
                </div>
            </div>
            <template #footer>
                <Button label="Aceptar" icon="pi pi-check" autofocus />
                <Button label="Cerrar" icon="pi pi-times" class="p-button-secondary p-button-outlined" @click="displayEntregas = false" />
            </template>
        </Dialog>
        <!-- END: Descargar Entregas Masivas -->
        <Toast />
    </div>
</template>
<script>
import { computed, ref, watch } from 'vue'
import storeMipres from '../stores/storeMipres'
// import DropdownPrime from 'primevue/dropdown'
import { useStore } from '@/store'
import axios from 'axios'
// import SideMenuTooltip from "@/components/side-menu-tooltip/Main.vue"
export default {
    components: {
        // DropdownPrime
    },

    setup () {
        const store = useStore()
        const storeEntregasList = computed(() => storeMipres.getters.getEntregasFact)
        const paginator = computed(() => storeMipres.getters.getPaginator)

        const displayEntregas = ref(false)
        const verEntregasFactura = ref(false)
        const searchEntrega = ref('')
        const facturas = ref('')
        const seleccionadasFact = ref([])
        const buscadasFact = ref([])

        const exportar = [
            { name: 'pdf entregas por fact', action: 'MipresFecha' }
        ]

        const insertFact = () => {
            const a = facturas.value.split(' ')
            if (facturas.value.split(' ').length === 1) {
            if (!seleccionadasFact.value.find(a => a === facturas.value)) {
                if (facturas.value) {
                seleccionadasFact.value.push(facturas.value)
                }
            }
            } else {
                for (const i of a) {
                    if (!seleccionadasFact.value.find(a => a === i)) {
                        if (facturas.value) {
                        seleccionadasFact.value.push(i)
                        }
                    }
                }
                buscadasFact.value = seleccionadasFact.value
            }
            facturas.value = ''
        }

        const limpiar = () => {
            seleccionadasFact.value = []
        }

        const borrarOrden = (indice) => {
            seleccionadasFact.value.splice(indice, 1)
            buscadasFact.value = seleccionadasFact.value
        }

        const modalDescargapdf = () => {
            var params = { fact: seleccionadasFact.value, search: '' }
            const url = `${process.env.VUE_APP_MICROSERVICE_API_MIPRES}/export-entregas-fact-pdf`
            const token = store.getters['auth/getToken']
            const obj = { ...params, token }
            console.log('url', url)
            const res = axios.getUri({ url, params: obj })
            window.open(res, '_blank')
            console.log(res)
        }

        const EntregasFactura = () => {
            const params = {
                    limit: 10,
                    offset: 0,
                    search: searchEntrega.value,
                    fact: seleccionadasFact.value
                }
            storeMipres.dispatch('searchEntregaFact', { ...params })
            console.log('storeEntregasList', storeEntregasList.value)
        }

        const tipoEstado = (estado) => {
            switch (estado) {
                case '0':
                    return 'bg-red-200 text-white'
                case '1':
                    return 'bg-green-light text-white'
                default:
                    return 'bg-red-200 text-danger'
            }
        }

        const modalEntregas = () => {
            displayEntregas.value = true
        }

        watch(() => searchEntrega.value, (s) => {
            EntregasFactura()
        })

        return {
            limpiar,
            borrarOrden,
            EntregasFactura,
            insertFact,
            modalEntregas,
            tipoEstado,
            modalDescargapdf,
            exportar,
            displayEntregas,
            verEntregasFactura,
            facturas,
            seleccionadasFact,
            buscadasFact,
            paginator,
            storeEntregasList
            // can
        }
    }
}
</script>
<style lang="scss">
    #mipres-programacion .p-dialog-header{
        width: 100%;
    }
    .bg-gray{
        background-color: lightgray;
    }
    .p-datatable .p-datatable-thead > tr > th {
        opacity: 1!important;
    }
    .bg-green-light {
       background-color: #58AB3045;
    }
</style>
